import {useEffect, useState} from 'react';
import styled from 'styled-components';
import Util from './Util';

const fadeMS = 250;

const LoadingEllipsis = props => {
    const numDots = 3;
    const [currDot, setCurrDot] = useState(0);

    useEffect(() => {
        Util.wait(fadeMS).then(() => setCurrDot((currDot + 1) % numDots));
    }, [currDot]);

    const dotIs = [0];
    for (let i = 0; i < numDots - 1; i++) dotIs.push(i + 1);

    const on = {opacity: 1};

    return (
        <EllipsisContainer>
            {props.children}
            {dotIs.map(dotI => <Dot key={dotI} style={dotI === currDot ? on : {}}/>)}
        </EllipsisContainer>
    );
};

const dotSize = 6;
const dotUnit = 'px';

const EllipsisContainer = styled.div`
  // height: ${dotSize * 2}${dotUnit};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  gap: ${dotSize / 1.5}${dotUnit};

`;

const Dot = styled.div`
  background-color: white;
  height: ${dotSize}${dotUnit};
  width: ${dotSize}${dotUnit};
  border-radius: ${dotSize / 2}${dotUnit};
  opacity: .25;
  transition: opacity ${fadeMS}ms ease;
`;

export default LoadingEllipsis;