import './GameBoard.css';
import Util, {setBestFontSize} from './Util';
import Data from './Data';
import {useEffect, useLayoutEffect, useState} from 'react';
import SFX from './SFX';

const unityTransform = {sX: 1, sY: 1, tX: 0, tY: 0};

const CurrentClue = () => {
    const [isShown, setIsShown] = useState(false);
    const [transform, setTransform] = useState(unityTransform);

    const currentClue = Data.currentClue();

    const clueTime = currentClue?.time;
    const isDD = currentClue?.isDD;
    const wagerNeeded = isDD && !Util.hasValue(currentClue?.value);

    const delay = isDD ? 0 : 500;
    const duration = isDD ? 1200 : 750;

    useEffect(() => {
        if (Date.now() + Data.serverDelay() - clueTime > delay + duration) {
            setTransform(unityTransform);
            setIsShown(true);
        } else {
            const [catI, clueI] = Data.currentClueIndices();
            if (catI !== null && clueI != null) {
                setTransform(calculateTransforms(catI, clueI));
            }
            Util.wait(delay + 10).then(() => {
                setIsShown(true);
                setTransform(unityTransform);
            });
        }

        return () => setIsShown(false);
    }, [isDD, clueTime, delay, duration]);

    useLayoutEffect(() => {
        const handleResize = () => {
            setBestFontSize('currentClueContainer', 'clueText');
            setBestFontSize('currentClueContainer', 'ddTitle');
        };
        return Util.onWindowResize(handleResize);
    }, [isDD, wagerNeeded, currentClue?.clue]);

    useEffect(() => {
        if (isDD && clueTime && Date.now() + Data.serverDelay() - clueTime < 1000) SFX.playHorn();
    }, [isDD, clueTime]);

    const isU = transform === unityTransform;
    const style = {
        transform: `translate(${transform.tX}px, ${transform.tY}px) rotateX(${(isU && isDD) ? '360deg' : '0'}) scale(${transform.sX}, ${transform.sY})`,
        transition: isU ? `transform ${duration}ms ease` : 'none',
        opacity: isShown ? 1 : 0,
    };

    return (
        <div className={'GameBoardOverlay'} id={'currentClueContainer'} style={style}>
            {!wagerNeeded && <div className={'CategoryOverlay'}>{currentClue.category}: {currentClue.value}</div>}
            {!wagerNeeded && <div id={'clueText'}>{currentClue.clue}</div>}
            {wagerNeeded && <div id={'ddTitle'}>INTERMITTENT REDOUBLER</div>}
        </div>
    );
};

export default CurrentClue;

function calculateTransforms(catI, clueI) {
    const boardRect = document.getElementById('mainScreenContentContainer')?.getBoundingClientRect();
    const clueRect = document.getElementById(`clue-${catI}-${clueI}`)?.getBoundingClientRect();
    if (!boardRect || !clueRect) return null;
    const sX = clueRect.width / boardRect.width;
    const sY = clueRect.height / boardRect.height;
    const tX = clueRect.left - boardRect.left - boardRect.width * (1 - sX) / 2;
    const tY = clueRect.top - boardRect.top - boardRect.height * (1 - sY) / 2;
    return {sX: sX, sY: sY, tX: tX, tY: tY};
}