import {useState} from 'react';
import styled from 'styled-components';
import Data from './Data';

export default function Setup() {
    const [inputName, setInputName] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();

        if (!inputName || inputName.length === 0) return;

        let newName = inputName.toLowerCase();
        newName = newName.charAt(0).toUpperCase() + newName.slice(1);

        const playerList = Object.values(Data.playerNames());
        if (playerList.includes(newName)) {
            setErrorMessage('Name taken! Choose another');
            setInputName('');
            return;
        }
        Data.setPlayer(newName);
    };

    return (
        <SetupContainer>
            <h1>Welcome!</h1>
            <SetupForm onSubmit={handleSubmit}>
                {errorMessage && <div id={'errorMessage'}>{errorMessage}</div>}
                <SetupTextInput placeholder={'Enter your name to play!'}
                                value={inputName}
                                onChange={e => setInputName(e.target.value.replace(/[^a-zA-Z]/g, ''))}
                />
                <SetupSubmit value={'Play!'}/>
                <div>&nbsp;</div>
                <button onClick={Data.setAsDisplay}>Just Watch</button>
            </SetupForm>
        </SetupContainer>
    );
}

const SetupContainer = styled.div`
  padding: 10px;
  text-align: center;
  max-width: 100%;
  height: 100%;
  width: 400px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SetupForm = styled.form`
  font-size: large;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > #errorMessage {
    color: red;
    font-size: medium;
    text-align: center;
    background-color: var(--backgroundColor);
    border-radius: 3px;
    padding: 3px 12px;
    flex: 1 1 auto;
  }
`;

const SetupTextInput = styled.input.attrs({
    type: 'text',
})`
  width: 100%;
  padding: 14px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: white;
  color: black;
  text-align: center;
  font-size: large;
`;

const SetupSubmit = styled.input.attrs({
    type: 'submit',
})`
  width: 100%;
  background-color: var(--jBlue1);
  color: white;
  padding: 12px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-family: JeofontHeavy, "Helvetica Neue", Korinna, sans-serif;
  font-size: x-large;


  &:hover {
    background-color: var(--jBlue2);
  }

  &:active {
    background-color: var(--jBlue3);
  }
`;
