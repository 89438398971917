import './GameBoard.css';
import Util, {setBestFontSize} from './Util';
import Data from './Data';
import {useEffect, useState, useLayoutEffect} from 'react';
import SFX from './SFX';

const FinalClue = () => {
    const [revealSFXPlayed, setRevealSFXPlayed] = useState(false);
    const clue = Data.currentClue();
    const finalCatRevealed = Data.finalCatRevealed();
    const finalClueRevealed = !!Data.finalClueTime();

    useEffect(() => {
        if (revealSFXPlayed && !finalCatRevealed) {
            setRevealSFXPlayed(false);
        } else if (!revealSFXPlayed && finalCatRevealed) {
            setRevealSFXPlayed(true);
            SFX.playReveal();
        }
    }, [revealSFXPlayed, finalCatRevealed]);

    useLayoutEffect(() => {
        const handleResize = () => {
            setBestFontSize('currentClueContainer', 'clueText');
        };
        return Util.onWindowResize(handleResize);
    }, [finalClueRevealed]);

    return (
        <div className={'GameBoardOverlay'} id={'currentClueContainer'}>
            {Data.gameOver() && <div id={'ddTitle'}>Winner: {Data.nameFor(Data.sortedScores()[0].tabID)}</div>}
            {!Data.gameOver() &&
                <>
                    {finalClueRevealed && <div className={'CategoryOverlay'}>({clue.category})</div>}
                    {finalCatRevealed && !finalClueRevealed && <div id={'clueText'}>{clue.category}</div>}
                    {finalClueRevealed && <div id={'clueText'}>{clue.clue}</div>}
                    {!finalCatRevealed && <div id={'ddTitle'}>ULTIMATE IMPERILMENT</div>}
                </>
            }
        </div>
    );
};

export default FinalClue;