import ding from './assets/ding.m4a';
import timeout from './assets/timeout.m4a';
import ponder from './assets/ponder.m4a';
import horn from './assets/horn.m4a';

const SFX = {
    soundOn: () => _player.soundOn,
    setSoundOn: soundOn => {
        _player.soundOn = soundOn;
        if (soundOn) SFX.playReveal();
        else _player.stopSound();
    },

    playReveal: () => _player.playSound(ding),
    playTimeout: () => _player.playSound(timeout),
    playPonder: () => _player.playSound(ponder),
    playHorn: () => _player.playSound(horn),
};

export default SFX;

class SoundEffectPlayer {
    constructor() {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.buffers = {};
        this.activeSource = null;
        this.loadSound(require('./assets/ding.m4a'), false);
        this.soundOn = false;
    }

    loadSound(soundPath, playOnLoad) {
        if (this.buffers[soundPath]) return console.warn(`'${soundPath}' is already loaded.`);

        fetch(soundPath)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => this.audioContext.decodeAudioData(arrayBuffer))
            .then(audioBuffer => {
                this.buffers[soundPath] = audioBuffer;
                console.log(`Sound '${soundPath}' loaded.`);
                if (playOnLoad) this.playSound(soundPath);
            })
            .catch(error => {
                console.error(`Error loading sound '${soundPath}':`, error);
            });
    }

    playSound(soundPath) {
        if (!this.soundOn) return;

        if (this.buffers[soundPath]) {
            const audioBuffer = this.buffers[soundPath];

            const source = this.audioContext.createBufferSource();
            source.buffer = audioBuffer;

            this.activeSource = source;
            source.connect(this.audioContext.destination);
            source.start();

            source.onended = () => {
                source.disconnect();
                if (this.activeSource === source) this.activeSource = null;
            };

            console.log(`Playing sound '${soundPath}'.`);
        } else {
            this.loadSound(soundPath, true);
        }
    }

    stopSound() {
        this.activeSource?.stop();
    }
}

const _player = new SoundEffectPlayer();