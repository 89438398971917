import Data from './Data';

const Util = {
    isDev: () => process.env.NODE_ENV === 'development',

    tabID: () => {
        const tabIDStorageKey = 'tabId';
        let tabID = localStorage.getItem(tabIDStorageKey);
        if (!tabID) {
            tabID = Math.random().toString(36).substring(2);
            localStorage.setItem(tabIDStorageKey, tabID);
        }
        return tabID;
    },

    username: () => Data.playerNames()[Util.tabID()],
    score: () => Data.scoreFor(Util.tabID()),

    isDisplay: () => Data.displayIDs().includes(Util.tabID()),
    isPlayer: () => Data.playerIDs().includes(Util.tabID()),
    isSetup: () => Util.isDisplay() || Util.isPlayer(),

    hasControl: () => Data.controllingID() === Util.tabID(),

    isLance: () => {
        const lanceIDs = [
            '5kbuizt', // iPad dev
            'ynq7hf4', // mac dev
            'b5dkpqw', // mac prod
            '2yh4rca', // iOS dev
            '6t4d9uq', // iOS prod
        ];
        return lanceIDs.includes(Util.tabID())
            || Data.playerIDs().every(id => !lanceIDs.includes(id));
    },
};

export default Util;

// ################ GAME STORAGE ################

const gameFilenameKey = 'gameFilename';
const gameStorageKey = 'gameStorage';

Util.storedGameFile = () => localStorage.getItem(gameFilenameKey);

let _game = null;
Util.game = () => {
    if (!_game) _game = JSON.parse(localStorage.getItem(gameStorageKey));
    return _game;
};

Util.setStoredGameFile = filename => {
    return new Promise((resolve, reject) => {
        if (!filename) {
            _game = null;
            localStorage.removeItem(gameFilenameKey);
            localStorage.removeItem(gameStorageKey);
            return resolve();
        }

        const storedGameFile = localStorage.getItem(gameFilenameKey);
        if (filename === storedGameFile) return resolve();

        _game = null;
        localStorage.setItem(gameFilenameKey, filename);
        localStorage.removeItem(gameStorageKey);

        fetch('./games/' + filename)
            .then(response => response.json())
            .then(json => {
                const gameString = JSON.stringify(json);
                localStorage.setItem(gameStorageKey, gameString);
                resolve();
            }).catch(reject);
    });
};


// ################ JS HACKS ################

Util.onWindowResize = doThis => {
    doThis();
    window.addEventListener('resize', doThis);
    return () => window.removeEventListener('resize', doThis);
};

// ################ UTILITY ################

Util.wait = delay => new Promise(resolve => setTimeout(resolve, delay ?? 1000));

Util.copy = obj => !isRealObj(obj) ? obj : JSON.parse(JSON.stringify(obj));
Util.hasValue = (...vals) => vals.every(val => val !== null && val !== undefined);

Util.randomElement = a => a[Math.floor(Math.random() * a.length)];

export function setBestFontSize(containerID, textID, pxMax = 100) {
    const container = document.getElementById(containerID);
    const text = document.getElementById(textID);

    if (!container || !text) return;

    let low = 1;
    let high = Math.min(pxMax, container.offsetWidth * .2);
    let mid, currentFontSize;

    while (low < high) {
        mid = Math.floor((low + high) / 2);
        currentFontSize = mid + 'px';
        text.style.fontSize = currentFontSize;

        if (text.offsetHeight <= container.offsetHeight && text.offsetWidth <= container.offsetWidth) {
            low = mid + 1; // Font size fits, increase lower bound
        } else {
            high = mid - 1; // Font size doesn't fit, decrease upper bound
        }
    }

    // subtract 1 because we increased lower bound after the last successful fit
    // if (containerID.endsWith('0')) console.debug(containerID, textID, low - 1);
    text.style.fontSize = low - 1 + 'px';
}

const isRealObj = val => val && typeof val === 'object';

export function objDiff(inObj, outObj) {
    const diff = {};

    // check for value updates
    for (let key of Object.keys(outObj)) {
        if (inObj[key] === undefined) { // value was added
            diff[key] = outObj[key];
        } else if (isRealObj(inObj[key]) && isRealObj(outObj[key])) { // recurse
            const nestedDiff = objDiff(inObj[key], outObj[key]);
            if (Object.keys(nestedDiff).length === 0) continue; // if no keys, no changes
            if (isRealObj(nestedDiff) && Array.isArray(inObj[key]) && Array.isArray(outObj[key])) {
                diff[key] = [];
                for (const i of Object.keys(nestedDiff)) {
                    diff[key][i] = nestedDiff[i];
                }
            } else {
                diff[key] = nestedDiff;
            }
        } else if (inObj[key] !== outObj[key]) { // value was updated
            diff[key] = outObj[key];
        }
    }

    // check for removals
    for (let key of Object.keys(inObj)) {
        if (outObj[key] === undefined) {
            diff[key] = null;
        }
    }

    return diff;
}

export function debugObjPrint(obj) {
    let result = '';

    function truncateIfStr(str, maxLength = 15) {
        if (typeof str === 'string' && str.length > maxLength) {
            return str.substring(0, maxLength) + '...';
        }
        return str;
    }

    function traverseObject(obj, parentIndent = '') {
        for (const [i, key] of Object.keys(obj).entries()) {
            const currentIndent = i === 0 ? '' : parentIndent;

            const isA = Array.isArray(obj); // wrap array indices in []
            const keyHeader = `${isA ? '[' : ''}${key}${isA ? ']' : ''}: `;
            const newHeader = `${currentIndent}${keyHeader}`;

            if (isRealObj(obj[key])) {
                result += newHeader;
                traverseObject(obj[key], ' '.repeat(keyHeader.length) + parentIndent);
            } else {
                const value = truncateIfStr(obj[key]);
                result += `${newHeader}${value}\n`;
            }
        }
    }

    traverseObject(obj);

    if (!result.length) return;

    console.debug(`%c${result}`, 'font-family: "Andale Mono", monospace; font-size: x-small;');
}
