import styled from 'styled-components';
import Data from './Data';
import Util from './Util';
import React from 'react';
import PlayerInputForm from './PlayerInputForm';
import Buzzer from './Buzzer';
import Announcement from './Announcement';

const Controls = () => {
    const currentClue = Data.currentClue();
    const pendingGuess = Data.currentGuess();

    const isGuesser = pendingGuess?.tabID === Util.tabID();
    const answerRevealed = isGuesser && pendingGuess?.revealed;
    const guessHasResult = Util.hasValue(pendingGuess?.result);
    const canReveal = isGuesser && !answerRevealed && !guessHasResult;

    const isDD = !!currentClue?.isDD;
    const ddWagerNeeded = isDD && Util.hasControl() && !Util.hasValue(currentClue?.value);

    const tabID = Util.tabID();
    const tabIDs = Data.playerIDs();
    const wagers = Data.finalWagers();

    const isFinal = Data.isFinalRound();
    const finalCatRevealed = isFinal && Data.finalCatRevealed();
    const finalClueTime = Data.finalClueTime();

    const finalWagerNeeded = isFinal && finalCatRevealed && !Util.hasValue(wagers[tabID]);
    const allWagersDone = isFinal && tabIDs.every(tabID => Util.hasValue(wagers[tabID]));

    const finalResponsesLocked = Data.finalResponsesLocked();

    let i = 0;
    const children = [
        !isFinal && !isDD && <Buzzer key={i++}/>,
        isFinal && !finalCatRevealed && <button key={i++} onClick={Data.revealFinalCat}>Reveal Category</button>,
        allWagersDone && !finalClueTime && <button key={i++} onClick={Data.revealFinalClue}>Reveal Clue</button>,
        (ddWagerNeeded || finalWagerNeeded) && <PlayerInputForm key={i++}/>,
        finalClueTime && !finalResponsesLocked && <PlayerInputForm key={i++} isText={true}/>,

        canReveal && (
            <ButtonRow key={i++} style={{textAlign: 'center'}}>
                Say your answer out loud! 🎤
                <div>
                    <button onClick={Data.revealAnswerToGuesser}>I've said it</button>
                </div>
            </ButtonRow>
        ),

        // pendingGuess && <Announcement/>,

        answerRevealed && !guessHasResult && (
            <ButtonRow key={i++}>
                Answer: {currentClue.answer}
                <div>
                    <button style={{color: 'red'}}
                            onClick={e => Data.resultGuess(false)}>
                        ✗
                    </button>
                    <button style={{color: 'lime'}}
                            onClick={e => Data.resultGuess(true)}>
                        ✓
                    </button>
                </div>
            </ButtonRow>
        ),
    ].filter(ch => !!ch);

    if (!children.length) return null;

    return (
        <ControlsContainer className={'SideScreenContent'}>
            {children}
        </ControlsContainer>
    );
};

export default Controls;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  padding: 8px;
  border-radius: 6pt;
`;

const ButtonRow = styled.div`
font-size: large;

& > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 4pt;
    gap: 4pt;
  }

  & > div > * {
    flex: 1 1 auto;
    padding: 10px 0;
  }
`;
