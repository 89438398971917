import {useEffect, useState} from 'react';
import styled from 'styled-components';
import Util from './Util';
import Data from './Data';

export default function PlayerInputForm({isText}) {
    const [inputStr, setInputStr] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const currentScore = Util.score() ?? 0;
    const maxWager = Math.max(Data.maxRoundValue(), currentScore);

    useEffect(() => {
        setInputStr('');
        setErrorMessage(null);
    }, [isText]);

    const updateError = newErrorMsg => {
        setInputStr('');
        setErrorMessage(newErrorMsg);
        document.getElementById('playerInput').focus();
    };

    const handleSubmit = e => {
        e.preventDefault();
        e.stopPropagation();

        if (!inputStr || inputStr.length === 0) return updateError(null);

        const isFinal = Data.isFinalRound();

        if (isText) {
            Data.setFinalResponse(inputStr);
        } else {
            let theWager = Number(inputStr);

            const minValue = Data.minRoundValue();
            if (!Util.hasValue(theWager)) return updateError('Your wager must be a number');
            if (theWager < 0) return updateError('Your wager cannot be negative');
            if (theWager > maxWager) return updateError('You cannot wager that much');
            if (!isFinal && (theWager % minValue) !== 0) return updateError(`Your wager must be a multiple of ${minValue}`);

            if (isFinal) {
                Data.setFinalWager(theWager);
            } else {
                Data.setDDWager(theWager);
            }
        }
    };

    const currentResponse = Data.finalResponse();

    return (
        <PlayerForm onSubmit={handleSubmit}>
            {errorMessage && <div>{errorMessage}</div>}
            {isText && `Your response: ${currentResponse ?? '-'}`}
            {isText ?
                <PlayerInput type={'text'}
                             id={'playerInput'}
                             placeholder={'Update your response'}
                             value={inputStr}
                             onChange={e => setInputStr(e.target.value)}
                />
                :
                <PlayerInput type={'number'}
                             step={'disable'}
                             id={'playerInput'}
                             placeholder={`Max wager: ${maxWager}`}
                             value={inputStr}
                             onChange={e => setInputStr(e.target.value.replace(/[^0-9]/g, ''))}
                />
            }
            <SetupSubmit value={'Submit'}/>
            {isText && `You can update your response until time runs out`}
        </PlayerForm>
    );
}

const PlayerForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 5pt;
  font-size: small;

  & > div {
    color: red;
    font-size: small;
    text-align: center;
  }
`;

const PlayerInput = styled.input`
  width: 100%;
  padding: 10pt;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: black;
  text-align: center;
`;

const SetupSubmit = styled.input.attrs({
    type: 'submit',
})`
  width: 100%;
  background-color: var(--jBlue1);
  color: white;
  padding: 10pt;
  border: none;
  border-radius: 6px;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: var(--jBlue2);
    }
  }

  &:active, &:focus {
    background-color: var(--jBlue3);
  }
`;
