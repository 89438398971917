import {useState, useEffect} from 'react';
import Util from './Util';
import styled from 'styled-components';
import Data from './Data';

function ProgressBar({timer}) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const now = Date.now() + Data.serverDelay();
        const duration = timer.end - timer.start;
        const msLeft = Math.round(Math.max(0, timer.end - now));
        setProgress(msLeft / duration);

        if (msLeft) Util.wait(Math.max(timer.start - now, 10)).then(() => setProgress(0));
    }, [timer]);

    const now = Date.now() + Data.serverDelay();
    const msLeft = Math.round(Math.max(0, timer.end - now));

    const style = {
        width: `${Math.min(progress, 1) * 100}%`,
        transition: progress === 0 ? `width ${msLeft}ms linear` : 'none',
    };

    return (
        <ProgressBarContainer>
            <div style={style}/>
        </ProgressBarContainer>
    );
}

export default ProgressBar;

const ProgressBarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: difference;
  overflow: hidden;
  border-radius: 6px;

  & > div {
    height: 100%;
    background: white;
  }
`;