import React, {useEffect, useState} from 'react';
import Util from './Util';
import styled from 'styled-components';
import Data from './Data';
import doneImg from './assets/done.png';
import passImg from './assets/pass.png';

const Buzzer = () => {
    const [enabled, setEnabled] = useState(true);
    const [flashOn, setFlashOn] = useState(false);

    const hasGuessed = Data.prevGuessIDs().includes(Util.tabID());
    const everyonePassed = Data.resolvedIDs().length === Data.playerIDs().length;
    const shouldFlash = Data.canBuzz() && !hasGuessed && !everyonePassed;

    useEffect(() => {
        let flashInterval = null;

        if (shouldFlash) {
            setFlashOn(true);
            flashInterval = setInterval(() => {
                setFlashOn(prev => !prev);
            }, 100);
        }

        return () => {
            clearInterval(flashInterval);
            setFlashOn(false);
        };
    }, [shouldFlash]);

    const buzzAction = e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'mousedown' && e.buttons === 0) return; // prevent mousedown on touch screens
        if (!Data.canBuzz()) {
            setEnabled(false);
            Util.wait(250).then(() => setEnabled(true));
        } else {
            setEnabled(false);
            Util.wait(Math.random() * 250)
                .then(() => {
                    Data.buzzIn();
                    setEnabled(true);
                });
        }
    };

    const containerStyle = {
        border: `3px dotted ${flashOn ? 'white' : 'transparent'}`,
    };

    const passButtonStyle = {
        backgroundImage: `url(${Data.hasResolved() ? doneImg : passImg})`,
    };

    return (
        <BuzzContainer>
            <PassButton onClick={Data.pass}>
                <div style={passButtonStyle}/>
            </PassButton>
            <BuzzButton style={containerStyle}
                        disabled={!enabled}
                        onTouchStart={buzzAction}
                        onMouseDown={buzzAction}
            >
                🐝&nbsp;🐝&nbsp;🐝
            </BuzzButton>
        </BuzzContainer>
    );
};

export default Buzzer;

const BuzzContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12pt;
  padding: 4pt 6pt;
  position: relative;
`;

const PassButton = styled.button`
  position: relative;
  border-radius: 100pt;
  font-size: x-large;
  height: 2em;
  aspect-ratio: 1;
  overflow: visible;

  & > div {
    opacity: 0.9;
    position: absolute;
    --pad: -9pt;
    top: var(--pad);
    left: var(--pad);
    right: var(--pad);
    bottom: var(--pad);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: none;
  }
`;

const BuzzButton = styled.button`
  flex: 4 1 auto;
  padding: 8pt 0;
  border-radius: 100pt;
  font-size: x-large;
  background-color: var(--buttonDefault);

  &:not(:disabled) {
    background-color: var(--jBlue1);
  }

  @media (hover: hover) {
    &:not(:disabled):hover {
      background-color: var(--jBlue2);
    }
  }

  &:not(:disabled):active {
    opacity: 0.5;
    background-color: var(--jBlue1);
  }

  &:disabled {
    opacity: 0.5;
    background-color: var(--buttonDefault);
  }
`;