import styled from 'styled-components';
import ProgressBar from './ProgressBar';
import React, {useEffect, useRef, useState} from 'react';
import Data from './Data';
import Util from './Util';
import SFX from './SFX';

const StatusDisplay = () => {
    const [timer, setTimer] = useState(null);

    const currentClue = Data.currentClue();
    const currentClueTime = currentClue?.time;
    const currentClueText = currentClue?.clue;
    const clueRevealed = currentClue?.revealed;
    const isDD = currentClue?.isDD;

    const pendingGuess = Data.currentGuess();
    const pendingGuesserID = pendingGuess?.tabID;
    const pendingGuessTime = pendingGuess?.time;
    const guessRevealed = pendingGuess?.revealed;
    const prevGuessCount = Data.prevGuessIDs().length;

    const canBuzz = Data.canBuzz();

    const isFinal = Data.isFinalRound();
    const finalClueTime = Data.finalClueTime();
    const finalResponsesLocked = Data.finalResponsesLocked();

    const timerFinalResponses = isFinal && finalClueTime && !finalResponsesLocked;
    const timerCurrentGuess = pendingGuesserID && !guessRevealed && !clueRevealed && !isFinal;
    const timerClueRead = currentClueTime && !canBuzz && !prevGuessCount && !clueRevealed && !isDD;

    const [announcement, setAnnouncement] = useState(null);
    useEffect(() => {

    }, [announcement]);

    useEffect(() => {
        let timeout;

        const nWords = (currentClueText ?? '').split(' ').length;
        const duration = Math.ceil(nWords / 3) * 1000;
        const now = Date.now() + Data.serverDelay();

        if (timerFinalResponses) {
            // collecting final responses...
            const startTime = finalClueTime;
            const endTime = startTime + 30000;
            if (Util.hasControl()) {
                timeout = setTimeout(Data.lockFinalResponses, endTime - now + 500);
            }
            if (now - startTime < 1000) SFX.playPonder();
            setTimer({start: startTime, end: endTime, now: now});
        } else if (timerCurrentGuess) {
            // waiting for current guess...
            const startTime = pendingGuessTime;
            const endTime = startTime + (isDD ? duration + 12000 : 8000);
            timeout = setTimeout(() => {
                if (pendingGuesserID === Util.tabID()) Data.timeoutGuess();
                SFX.playTimeout();
            }, endTime - now);
            setTimer({start: startTime, end: endTime, now: now});
        } else if (timerClueRead) {
            // clue is being read...
            const startTime = currentClueTime + 1000;
            const endTime = startTime + duration;
            if (Util.hasControl()) {
                timeout = setTimeout(Data.startBuzzing, endTime - now);
            }
            setTimer({start: startTime, end: endTime, now: now});
        }

        return () => {
            clearTimeout(timeout);
            setTimer(null);
        };
    }, [
        timerFinalResponses,
        timerCurrentGuess,
        timerClueRead,
        currentClueTime,
        currentClueText,
        finalClueTime,
        isDD,
        pendingGuessTime,
        pendingGuesserID,
    ]);

    // const message = Data.statusHistory()[0]?.status ?? `It's time to play Imperilment!`;
    // const prevMessage = Data.statusHistory()[1]?.status ?? null;

    return (
        <StatusDisplayContainer className={'SideScreenContent'}>
            <StatusBarContainer>
                <div>
                    {/*<MessageBanner message={message} prevMessage={prevMessage}/>*/}
                    {Data.statusHistory()[0]?.status ?? `It's time to play Imperilment!`}
                    {timer && <ProgressBar timer={timer}/>}
                </div>
            </StatusBarContainer>
            <StatusHistory/>
        </StatusDisplayContainer>
    );
};

export default StatusDisplay;

const StatusDisplayContainer = styled.div`
  transition: height .5s ease;
  overflow: hidden;
`;

const StatusBarContainer = styled.div`
  width: 100%;

  & > div {
    padding: 5pt;
    margin: 5pt;
    position: relative;
  }
`;

const StatusHistory = () => {
    const [isUpdating, setIsUpdating] = useState(true);
    const ref = useRef(null);

    const statusHistory = Data.statusHistory();
    const firstStatus = statusHistory[0]?.status;

    const shouldAnimate = Date.now() + Data.serverDelay() - (statusHistory[0]?.time ?? 0) < 1000;

    useEffect(() => {
        ref.current.scrollTop = 0;

        if (shouldAnimate) {
            Util.wait(10).then(() => setIsUpdating(false));
        } else {
            setIsUpdating(false);
        }

        return () => setIsUpdating(true);
    }, [firstStatus, shouldAnimate]);

    const style = {
        transform: `translateY(${isUpdating ? -100 : 0}%)`,
        transition: (isUpdating || !shouldAnimate) ? 'none' : 'transform .3s ease-out',
    };

    return (
        <StatusHistoryContainer ref={ref}>
            {statusHistory.slice(0, 16).map((status, i) =>
                <div key={i}
                     style={style}
                >
                    <div className={'StatusTime'}>
                        {(new Date(status.time)).toLocaleTimeString().slice(0, -3)}&nbsp;&#8211;&nbsp;
                    </div>
                    <div className={'StatusHistory'}>{status.status}</div>
                </div>)
            }
        </StatusHistoryContainer>
    );
};

const StatusHistoryContainer = styled.div`
  scroll-behavior: smooth;
  max-height: 4em;
  overflow: auto;
  width: 100%;
  border-top: 1px solid #ffffff90;
  padding: 2pt 5pt;
  z-index: 0;

  & > div {
    padding: 3pt 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    width: 100%;
  }

  & .StatusHistory {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1 1 auto;
    font-size: small;
  }

  & .StatusTime {
    flex: 0 0 auto;
    font-size: small;
  }
`;

// const MessageBanner = ({message, prevMessage}) => {
//     const [isAnimating, setIsAnimating] = useState(false);
//
//     useEffect(() => {
//         console.log(`update ${prevMessage.slice(0, 10)} -> ${message.slice(0, 10)}`);
//         Util.wait(10).then(() => setIsAnimating(false));
//
//         return () => setIsAnimating(true);
//     }, [message, prevMessage]);
//
//     const containerStyle = {
//         height: '36px',
//         // maxHeight: '2em',
//         overflow: 'hidden',
//     };
//
//     const msg1 = {
//         transform: `translateY(${isAnimating ? -100 : 0}%)`,
//         transition: isAnimating ? 'none' : 'transform .2s ease-out',
//         // position: 'fixed',
//         // top: 0,
//         // left: 0,
//         height: '100%',
//         margin: '8px 8px 0',
//     };
//
//     return (
//         <div style={containerStyle}>
//             <div style={msg1}>{message}</div>
//             <div style={{...msg1, top: '100%'}}>{prevMessage}</div>
//         </div>
//     );
// };
