import React, {useRef, useState} from 'react';
import Util from './Util';
import SFX from './SFX';
import {ButtonRow, ButtonRowContent, Popover} from './Popover';
import Data from './Data';
import LoadingEllipsis from './LoadingIcon';

const HeaderBar = () => {
    const [soundOn, setSoundOn] = useState(SFX.soundOn());
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [namesOpen, setNamesOpen] = useState(false);
    const playerFunctionRef = useRef(() => console.error('No player function set!'));

    const displayName = Util.isDisplay() ? '' : Util.username();

    const setRound = round => {
        Data.setRound(round);
        setSettingsOpen(false);
    };

    const setSoundLocal = (soundOn) => {
        SFX.setSoundOn(soundOn);
        setSoundOn(SFX.soundOn());
    };

    const setPlayerFunction = playerFunc => {
        if (!Util.hasValue(playerFunc)) {
            playerFunctionRef.current = () => console.error('No player function set!');
            setNamesOpen(false);
        } else {
            playerFunctionRef.current = playerFunc;
            setNamesOpen(true);
        }
    };

    const adjustScore = tabID => {
        const name = Data.nameFor(tabID);
        const dScore = Number(window.prompt(`Adjust ${name}'s score by:`));
        if (isNaN(dScore) || Math.abs(dScore) === Number.POSITIVE_INFINITY) {
            alert(`Invalid number: ${dScore}`);
        } else {
            Data.adjustScore(tabID, dScore);
        }
        setNamesOpen(false);
        setSettingsOpen(false);
    };

    const giveControl = tabID => {
        Data.setControl(tabID);
        setNamesOpen(false);
        setSettingsOpen(false);
    };

    const removePlayer = tabID => {
        const confirmMsg = `Are you sure you want to remove ${Data.nameFor(tabID)} from the game?\n\nThis cannot be undone.`;
        if (!window.confirm(confirmMsg)) return;
        Data.removePlayer(tabID);
        setNamesOpen(false);
        setSettingsOpen(false);
    };

    const confirmReset = () => {
        const confirmMsg = 'Are you sure you want to reset? \n\n Everyone will be kicked out and game status will be lost.';
        if (!window.confirm(confirmMsg)) return;
        Data.resetEverything();
        setSettingsOpen(false);
    };

    const game = Data.game();

    const delays = Data.delayResults();
    const hasDelay = delays.length === 3;
    let delayString = 'Testing connection...';
    if (hasDelay) {
        delayString = `Server offset: ${Data.serverDelay()}ms, trip: ${Data.serverRoundTrip()}ms`;
    }

    return (
        <div id={'header'}>
            {Util.isDev() && game &&
                <>
                    <button onClick={Data.unsetGame}>Unset Game</button>
                    <button onClick={Data.resetGame}>Reset Game</button>
                    <button onClick={Data.resetClue}>Reset Clue</button>
                </>
            }
            <h2>{displayName}</h2>
            <button onClick={_ => setSettingsOpen(true)}>⚙️</button>
            {settingsOpen &&
                <Popover close={() => setSettingsOpen(false)}>
                    <h1 className={'Title'}>Imperilment!</h1>
                    <ButtonRow>
                        Sound
                        <ButtonRowContent>
                            <button onClick={_ => setSoundLocal(false)}
                                    disabled={!soundOn}
                                    className={`${!soundOn && 'SelectedButton'}`}
                            >
                                Off 🔇
                            </button>
                            <button onClick={_ => setSoundLocal(true)}
                                    disabled={!!soundOn}
                                    className={`${soundOn && 'SelectedButton'}`}
                            >
                                On 🔊
                            </button>
                        </ButtonRowContent>
                    </ButtonRow>
                    {game && Util.isPlayer() &&
                        <>
                            <ButtonRow>
                                Set Imperilment Level
                                <ButtonRowContent>
                                    <button onClick={_ => setRound('j')}
                                            disabled={!Util.hasValue(game.j?.categories) || Data.isRound('j')}
                                            className={`${Data.isRound('j') && 'SelectedButton'}`}
                                    >
                                        Single
                                    </button>
                                    <button onClick={_ => setRound('j2')}
                                            disabled={!Util.hasValue(game.j2?.categories) || Data.isRound('j2')}
                                            className={`${Data.isRound('j2') && 'SelectedButton'}`}
                                    >
                                        Two-Fold
                                    </button>
                                    <button onClick={_ => setRound('fj')}
                                            disabled={!Util.hasValue(game.fj) || Data.isRound('fj')}
                                            className={`${Data.isRound('fj') && 'SelectedButton'}`}
                                    >
                                        Ultimate
                                    </button>
                                </ButtonRowContent>
                            </ButtonRow>
                            Amend Game
                            {<button onClick={_ => setPlayerFunction(adjustScore)}>Adjust Score&nbsp;🎚</button>}
                            {<button onClick={_ => setPlayerFunction(giveControl)}>Set Control&nbsp;🕹️</button>}
                            {<button onClick={_ => setPlayerFunction(removePlayer)}>Remove Player&nbsp;👋</button>}
                            <button onClick={Data.testDelay}
                                    style={{
                                        margin: '12px 0',
                                        padding: '9px 0',
                                    }}
                            >
                                {hasDelay && delayString}
                                {!hasDelay && <LoadingEllipsis>Testing connection&nbsp;</LoadingEllipsis>}
                            </button>
                        </>
                    }
                    {<button onClick={confirmReset}>Reset Everything&nbsp;&nbsp;❌</button>}
                </Popover>
            }
            {settingsOpen && namesOpen &&
                <Popover close={() => setPlayerFunction(null)}>
                    Which player?
                    {Data.playerIDs().map(id => {
                        return (
                            <button key={id} onClick={_ => playerFunctionRef.current?.(id)}>
                                {Data.nameFor(id)}
                            </button>
                        );
                    })}
                </Popover>
            }
        </div>
    );
};

export default HeaderBar;
