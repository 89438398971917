import './GameBoard.css';
import Util, {setBestFontSize} from './Util';
import {Fragment, useLayoutEffect} from 'react';
import Data from './Data';

const GameBoard = () => {
    const roundData = Data.round();
    const [currentCatI, currentClueI] = Data.currentClueIndices();

    if (!roundData) return null;

    return (
        <div id={'gameboard'}>
            {roundData.categories.map((category, catI) => {
                return (
                    <Fragment key={catI}>
                        <CategoryName catI={catI} name={category.name}/>
                        {category.clues?.map((clue, clueI) => {
                            const cluePlayed = Data.wasPlayed(catI, clueI);
                            const classNames = cluePlayed ? 'PlayedClue' : Util.hasControl() ? 'SelectableClue' : '';
                            const selected = currentCatI === catI && currentClueI === clueI;
                            return (
                                <div className={`BoardSquare ClueValue ${classNames}`}
                                     style={{
                                         gridColumn: catI + 1,
                                         gridRow: clueI + 3,
                                         borderWidth: selected ? '2px' : 0,
                                     }}
                                     key={`${catI}-${clueI}`}
                                     id={`clue-${catI}-${clueI}`}
                                     onClick={(Util.hasControl() && !cluePlayed) ? (_ => Data.setCurrentClue(catI, clueI)) : null}
                                >
                                    {cluePlayed ? null : `${roundData.values[clueI]}${Util.isDev() && clue.isDD ? '*' : ''}`}
                                </div>
                            );
                        })}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default GameBoard;

const CategoryName = ({catI, name}) => {
    const catID = `cat-${catI}`;
    const catTextID = `cat-${catI}-text`;

    useLayoutEffect(() => {
        return Util.onWindowResize(() => setBestFontSize(catID, catTextID, 50));
    }, [catID, catTextID, name]);

    return (
        <div className={'CategoryName BoardSquare'}
             key={catI}
             id={catID}
             style={{gridColumn: catI + 1}}
        >
            <div id={catTextID}>
                {name.replaceAll('\\\'', '\'')}
            </div>
        </div>
    );
};