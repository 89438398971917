import {useState} from 'react';
import styled from 'styled-components';
import fileData from './assets/gameList.json';
import Data from './Data';
import Util from './Util';

const FileListContainer = styled.div`
  flex: 1 1 auto;
  max-width: calc(min(1000px, 100%));
  min-width: 300px;
  padding: 20px 10px 20px 30px;
  background-color: var(--backgroundColor);
  border-radius: 10px;
  --blurRadius: 20px;
  backdrop-filter: blur(var(--blurRadius));
  -webkit-backdrop-filter: blur(var(--blurRadius));
`;

export default function FileList() {
    const fileList = [];
    for (const year in fileData) {
        for (const month in fileData[year]) {
            for (const day in fileData[year][month]) {
                const fileObj = fileData[year][month][day];
                if (fileObj.missingFJ || fileObj.missingClues > 8 || fileObj.missingMedia > 20) continue;

                const newFileObj = Util.copy(fileObj);
                newFileObj.date = new Date(year, month - 1, day);

                const playCount = Data.gamePlayCount(fileObj.filename);
                if (playCount > 0) newFileObj.playCount = playCount;

                fileList.push(newFileObj);
            }
        }
    }
    fileList.sort((a, b) => b.date - a.date);

    let years = fileList.reduce((acc, obj) => {
        const year = obj.date.getFullYear();
        if (!acc[year]) {
            acc[year] = [];
        }
        acc[year].push(obj);
        return acc;
    }, {});

    return (
        <FileListContainer>
            <h2>Select a game</h2>
            {Object.entries(years).slice().reverse().map(([year, files]) => (
                <Year key={year} year={year} files={files}/>
            ))}
        </FileListContainer>
    );
}

const YearButton = styled.button`
  margin: 4px;
  padding: 3pt 8pt;
  font-family: Korinna, sans-serif;
`;

const YearUList = styled.ul`
  margin: 0;
  padding: 5px 0 0 20px;
  list-style-type: none;
  width: 100%;
`;

const YearListItem = styled.li`
  padding-bottom: 10px;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  & > div > .GameSelectButton {
    padding: 3pt 8pt;
  }

  & > div > .MissingMessage {
    white-space: nowrap;
    //display: inline;
    font-size: small;
    color: white;
    border-radius: 2pt;
    background-color: red;
    padding: 2px 4px;
    margin: 0 6px;
    //vertical-align: center;
    flex: 0 0 auto;
  }

  & > .GameComments {
    margin: 2px 0 0 10px;
    font-size: medium;
    text-indent: 20px hanging;
  }
`;

function Year({year, files}) {
    const [collapsed, setCollapsed] = useState(true);
    const dateOptions = {month: 'short', day: 'numeric'};

    return (
        <div>
            <YearButton onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? '⏵️' : '⏷'} {year} ({files.length})
            </YearButton>
            {!collapsed && (
                <YearUList>
                    {files.map(({filename, comments, date, missingClues, missingMedia, playCount}) => {
                        const missingSomething = missingClues || missingMedia;
                        const dateString = date.toLocaleDateString('en-US', dateOptions);
                        return (
                            <YearListItem key={filename}>
                                <div>
                                    <button className={'GameSelectButton'}
                                            onClick={() => Data.setGame(filename, dateString)}
                                    >
                                        {dateString}
                                    </button>
                                    {missingSomething &&
                                        <span className={'MissingMessage'}>
                                            missing&nbsp;
                                            {missingClues && `${missingClues} clue${missingClues > 1 ? 's' : ''}`}
                                            {missingClues && missingMedia && ', '}
                                            {missingMedia && `${missingMedia} media`}
                                        </span>
                                    }
                                    {!missingSomething &&
                                        <span style={{backgroundColor: 'limegreen'}}>complete</span>
                                    }
                                    {playCount > 0 &&
                                        <button className={'MissingMessage'}
                                                style={{backgroundColor: 'goldenrod'}}>
                                            played {playCount} time{playCount > 1 ? 's' : ''}
                                        </button>
                                    }
                                </div>
                                {comments && <div className={'GameComments'}>↳ {comments}</div>}
                            </YearListItem>
                        );
                    })}
                </YearUList>
            )}
        </div>
    );
}
