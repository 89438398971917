import styled from 'styled-components';
import Data from './Data';
import {useEffect, useState} from 'react';
import Util from './Util';

const Scoreboard = () => {
    const scores = Data.sortedScores();

    return (
        <ScoreboardContainer className={'SideScreenContent'}>
            {scores.map(({tabID, score}, i) => (
                <Score key={tabID} tabID={tabID} place={i}/>
            ))}
        </ScoreboardContainer>
    );
};

export default Scoreboard;

const ScoreboardContainer = styled.div`
  align-items: stretch;
  position: relative;
  padding: 2pt;

  & * {
    font-size: x-large;
  }
`;

const Score = ({tabID, place}) => {
    const [scoreUpdate, setScoreUpdate] = useState(null);
    const [score, setScore] = useState(Data.scoreFor(tabID));

    useEffect(() => {
        const unsub = Data.onScoreUpdate(tabID, newScore => {
            if (!Util.hasValue(newScore)) return;
            setScore(prevScore => {
                setScoreUpdate(newScore - prevScore);
                return newScore;
            });
        });
        return () => unsub();
    }, [tabID]);

    return (
        <ScoreContainer>
            <div className={'PlaceTag'}>{place + 1}.</div>
            <div className={'PlaceName'}>
                {Data.nameFor(tabID)}
                <ScoreUpdateBadge delta={scoreUpdate} score={score}/>
            </div>
            <div className={'PlaceScore'}>{score}</div>
        </ScoreContainer>
    );
};

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  gap: 4pt;
  position: relative;
  margin: 6pt;
  z-index: 0;

  & > .PlaceName {
    flex: 1 1 100%;
    position: relative;
  }
`;

const ScoreUpdateBadge = ({delta, score}) => {
    const [opacity, setOpacity] = useState(0);
    const [translateY, setTranslateY] = useState(50);

    const shouldShow = Util.hasValue(delta) && delta !== 0;

    useEffect(() => {
        const timeouts = [];

        if (shouldShow) {
            timeouts.push(
                setTimeout(() => {
                    setOpacity(1);
                    setTranslateY(0);
                }, 10),
            );

            timeouts.push(
                setTimeout(() => {
                    setOpacity(0);
                    setTranslateY(-50);
                }, 1500),
            );
        }

        return () => {
            timeouts.forEach(to => clearTimeout(to));
            setOpacity(0);
            setTranslateY(50);
        };
    }, [shouldShow, delta, score]);

    const style = {
        opacity: shouldShow ? opacity : 0,
        transform: `translateY(${translateY}%)`,
        transition: translateY === 50 ? 'none' : 'all 300ms',
    };

    return (
        <ScoreUpdateBadgeContainer>
            <div style={style}>{delta > 0 ? '+' : ''}{delta}</div>
        </ScoreUpdateBadgeContainer>
    );
};

const ScoreUpdateBadgeContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    color: black;
    font-size: medium;
    background-color: white;
    padding: 1pt 2pt;
    border-radius: 2pt;
    box-shadow: 0 0 5px #00000090;
  }
`;