import {useEffect, useState} from 'react';
import './App.css';
import Scoreboard from './Scoreboard';
import Controls from './Controls';
import GameBoard from './GameBoard';
import Setup from './Setup';
import FileList from './FileList';
import HeaderBar from './HeaderBar';
import Util from './Util';
import CurrentClue from './CurrentClue';
import Data from './Data';
import FinalClue from './FinalClue';
import StatusDisplay from './StatusDisplay';
import LoadingEllipsis from './LoadingIcon';
import Announcement from './Announcement';

export default function App() {
    // on load...
    useEffect(() => {
        // listen for all data changes
        Data.startUpdates(() => {
            setUpdateID(prevUpdateID => prevUpdateID + 1);
        });

        return () => Data.stopUpdates();
    }, []);

    const [, setUpdateID] = useState(0);

    let content;

    if (!Data.isLoaded()) { // first load
        content = <LoadingEllipsis>Loading</LoadingEllipsis>;
    } else if (!Util.isSetup()) {
        content = <Setup/>;
    } else if (!Data.game()) {
        content = Util.isPlayer() ? <FileList/> : <div>The game is being set up...</div>;
    } else {
        content =
            <>
                <div id={'mainScreen'}>
                    <div id={'mainScreenContentContainer'}>
                        {!Data.isFinalRound() && <GameBoard/>}
                        {Data.currentClue() && !Data.isFinalRound() && <CurrentClue/>}
                        {Data.isFinalRound() && <FinalClue/>}
                    </div>
                </div>
                <div id={'sideScreen'}>
                    {Util.isSetup() && <h1 className={'Title PCTitle'}>{Data.roundName()}</h1>}
                    {Util.isPlayer() && <Controls/>}
                    <StatusDisplay/>
                    <Scoreboard/>
                </div>
            </>;
    }

    return (
        <div className={'App'}>
            <HeaderBar/>
            {/*{Util.isSetup() && Data.game() && <h1 className={'Title PhoneTitle'}>{Data.roundName()}</h1>}*/}
            {/*{Data.announcement() && <Announcement/>}*/}
            <div id={'content'}>
                {content}
            </div>
        </div>
    );
}