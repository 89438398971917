import {initializeApp} from 'firebase/app';
import {
    child,
    get,
    getDatabase,
    increment,
    onValue,
    push,
    ref,
    runTransaction,
    serverTimestamp,
    set,
    update,
} from 'firebase/database';
import Util, {debugObjPrint, objDiff} from './Util';

const firebaseConfig = {
    apiKey: 'AIzaSyB6QvVxubsloNgHNHajIDSZapynWNtiPGY',
    authDomain: 'jeopardy-311b5.firebaseapp.com',
    databaseURL: 'https://jeopardy-311b5-default-rtdb.firebaseio.com',
    projectId: 'jeopardy-311b5',
    storageBucket: 'jeopardy-311b5.appspot.com',
    messagingSenderId: '922180363483',
    appId: '1:922180363483:web:ad8e1cf1589bb08d2c98fe',
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const refPrefix = Util.isDev() ? '/dev/' : '/prod/';
const gameRef = ref(db, refPrefix + 'game/');
const histRef = ref(db, refPrefix + 'hist/');

let _unsubscribes = [];
let _data = null;
let _gamePlayCounts = {};

const Data = {
    isLoaded: () => Util.hasValue(_data),

    startUpdates: triggerUpdate => {
        // load everything
        const rootUnsub = onValue(gameRef, snapshot => {
            const newData = snapshot.val() ?? {};

            if (Data.isLoaded() && Util.isDev()) {
                const diff = objDiff(_data, newData);
                debugObjPrint(diff);
            }

            _data = newData;

            if (!Data.delayResults().length && !Data.isTestingDelays) {
                Data.testDelay();
            }

            triggerUpdate();
        });
        _unsubscribes.push(rootUnsub);

        // on game change
        const gameUnsub = onValue(child(gameRef, 'gameFile'),
            snapshot => Util.setStoredGameFile(snapshot.val())
                .then(triggerUpdate)
                .catch(console.error),
        );
        _unsubscribes.push(gameUnsub);

        // get gameplay counts
        const playCountUnsub = onValue(histRef, snapshot => {
            _gamePlayCounts = snapshot.val() ?? {};
            triggerUpdate();
        });
        _unsubscribes.push(playCountUnsub);

        // test delays on refresh (in prod only)
        if (!Util.isDev()) Data.testDelay();
    },

    stopUpdates: () => {
        _unsubscribes.forEach(unsub => unsub());
        _unsubscribes = [];
    },
};

export default Data;

// ################ SERVER DELAY ################

Data.isTestingDelays = false;
Data.testDelay = () => {
    if (Data.isTestingDelays) return;
    Data.isTestingDelays = true;

    const testIs = [0, 1, 2];
    update(gameRef, {
        [`delay/${Util.tabID()}`]: null,
    }).then(() => {
        for (let testI of testIs) {
            const testRef = child(gameRef, `delay/${Util.tabID()}/${testI}`);
            Util.wait(testI * 1000).then(() => {
                runTransaction(testRef,
                    _ => ({
                        server: serverTimestamp(),
                        local: Date.now(),
                    }),
                    {applyLocally: false},
                ).then(_ => {
                    get(testRef)
                        .then(_ => update(testRef, {received: Date.now()}))
                        .then(() => Data.isTestingDelays = testI !== 2);
                }).catch(console.error);
            });
        }
    }).catch(console.error);
};

Data.delayResults = () => {
    const delays = _data?.delay?.[Util.tabID()]?.filter(d => Util.hasValue(d.received)) ?? [];
    return delays.map(del => {
        return {diff: del.server - del.local, round: del.received - del.local, ...del};
    });
};

Data.serverDelay = () => {
    const delays = Data.delayResults();
    if (!delays.length) return 0;
    const sum = delays.reduce((acc, curr) => acc + curr.diff, 0);
    return Math.round(sum / delays.length);
};

Data.serverRoundTrip = () => {
    const delays = Data.delayResults();
    if (!delays.length) return 0;
    const sum = delays.reduce((acc, curr) => acc + curr.round, 0);
    return Math.round(sum / delays.length);
};

// ################ PLAYERS ################

Data.setPlayer = name => {
    if (Util.isSetup()) return;
    const tabID = Util.tabID();
    const controllingID = Data.controllingID() ?? tabID;
    update(gameRef, {
        [`names/${tabID}/`]: name,
        [`scores/${tabID}/`]: 0,
        control: controllingID,
    }).catch(console.error);
    pushStatus(`${tabID} has entered the game!`);
};

Data.setAsDisplay = () => {
    if (Util.isSetup()) return;
    const tabID = Util.tabID();
    push(child(gameRef, 'displays/'))
        .then(childRef => set(childRef, tabID))
        .catch(console.error);
};

// returns an array of tabIDs
Data.playerIDs = () => Object.keys(_data?.names ?? {});
Data.displayIDs = () => Object.values(_data?.displays ?? {});

// returns an object of {tabID: playerName, ...}
Data.playerNames = () => _data?.names ?? {};
Data.nameFor = tabID => Data.playerNames()[tabID];

// returns an object of {tabID: score, ...}
Data.scores = () => _data?.scores ?? {};
Data.scoreFor = tabID => Data.scores()[tabID] ?? 0;

Data.sortedScores = () => {
    const scoreEntries = Object.entries(Data.scores());
    scoreEntries.sort((a, b) => b[1] - a[1]);
    return scoreEntries.map(([tabID, score]) => {
        return {tabID, score};
    });
};

Data.onScoreUpdate = (tabID, updateScore) => {
    return onValue(
        child(gameRef, `scores/${tabID}/`),
        snap => updateScore(snap.val()),
    );
};

// ################ CONTROL ################

Data.setControl = tabID => {
    update(gameRef, {
        control: tabID,
    }).catch(console.error);
    pushStatus(`${tabID} has control of the board`);
};

Data.controllingID = () => _data?.control;

Data.controllingName = () => {
    const controllingID = Data.controllingID();
    return Data.playerNames()[controllingID] ?? null;
};

// ################ STATUS ################

Data.statusHistory = () => {
    const statusObj = _data?.status ?? {};
    const statusKeys = Object.keys(statusObj);
    statusKeys.sort().reverse();
    return statusKeys.map(key => statusObj[key]);
};

const pushStatus = (message, isSticky) => {
    for (let id of Data.playerIDs()) message = message.replaceAll(id, Data.nameFor(id));
    push(child(gameRef, 'status'))
        .then(childRef => set(childRef, {
            status: message,
            time: serverTimestamp(),
            isSticky: isSticky ? true : null,
        }))
        .catch(console.error);
};

// ################ GAME ################

Data.game = () => Util.game();

Data.setGame = (filename, dateString) => {
    if (!Util.hasValue(filename)) return Data.unsetGame();

    update(gameRef, {
        round: 'j',
        gameFile: filename,
    }).catch(console.error);
    pushStatus(`Game from ${dateString} has loaded`);
    pushStatus(`${Data.controllingID()} has control of the board`);

    const fileKey = filename.replace(/\D/g, '');
    update(histRef, {
        [fileKey]: increment(1),
    }).catch(console.error);
};

Data.gamePlayCount = filename => {
    const fileKey = filename.replace(/\D/g, '');
    return _gamePlayCounts[fileKey] ?? 0;
};

Data.round = () => Data.game()?.[_data?.round];

Data.roundName = symbol => {
    switch (symbol ?? _data?.round ?? 'j') {
        case 'j2':
            return 'Two-fold Imperilment!';
        case 'fj':
            return 'Ultimate Imperilment!';
        default:
            return 'Imperilment!';
    }
};

Data.isRound = roundID => _data?.round === roundID;
Data.isFinalRound = () => _data?.round === 'fj';

Data.maxRoundValue = () => {
    if (Data.isFinalRound()) return 0;
    const roundValues = Data.round()?.values ?? [1000];
    return roundValues[roundValues.length - 1];
};

Data.minRoundValue = () => {
    if (Data.isFinalRound()) return 0;
    const roundValues = Data.round()?.values ?? [200];
    return roundValues[0];
};

Data.setRound = round => {
    update(gameRef, {
        round: round,
        currentClue: null,
        final: null,
    }).catch(console.error);
    pushStatus(`Its time for ${Data.roundName(round)}`);
};

Data.wasPlayed = (catI, clueI) => _data?.playedClues?.[_data?.round]?.[catI]?.[clueI] ?? false;

// ################ CURRENT CLUE ################

Data.currentClueIndices = () => [_data?.currentClue?.catI, _data?.currentClue?.clueI];

function extractTextFromLink(text) {
    const regex = /(\[(.*?)])\(.*?\)/g;
    return text
        .replace(regex, (_, capturedText) => capturedText)
        .replaceAll('([', '[')
        .replaceAll('])', ']');
}

Data.currentClue = () => {
    if (Data.isFinalRound()) return Data.game()?.fj;

    const [catI, clueI] = Data.currentClueIndices();
    if (!Util.hasValue(catI, clueI)) return null;

    const theRound = Data.round();
    const theClue = Util.copy(theRound?.categories?.[catI]?.clues?.[clueI]);

    if (theClue) {
        theClue.value = theClue.isDD ? _data?.currentClue?.value : theRound?.values[clueI];
        theClue.time = _data?.currentClue?.time;
        theClue.clue = extractTextFromLink(theClue.clue);
        theClue.category = theRound?.categories?.[catI]?.name;
    }

    return theClue;
};

Data.setCurrentClue = (catI, clueI) => {
    update(gameRef, {
        currentClue: {
            catI: catI,
            clueI: clueI,
            time: serverTimestamp(),
        },
    }).then(() => {
        const clue = Data.currentClue();
        if (clue.isDD) {
            const tabID = Util.tabID();
            const maxWager = Math.max(Data.scoreFor(tabID), Data.maxRoundValue());
            pushStatus('Answer there...');
            Util.wait().then(() => pushStatus(`${Util.tabID()} can wager up to ${maxWager}`));
        } else {
            pushStatus(`${clue.value}, ${clue.category}`);
        }
    }).catch(console.error);
};

Data.endCurrentClue = newControlID => {
    const [catI, clueI] = Data.currentClueIndices();
    if (!Util.hasValue(catI, clueI) && !Data.isFinalRound()) return;

    pushStatus(`Answer was "${Data.currentClue()?.answer}"`.replaceAll('""', '"'));

    Util.wait().then(() => {
        if (Data.isFinalRound()) {
            Data.endGame();
        } else {
            update(gameRef, {
                buzz: null,
                guesses: null,
                currentClue: null,
                pass: null,
                [`playedClues/${_data?.round}/${catI}/${clueI}/`]: true,
            }).catch(console.error);

            const newControl = Data.playerIDs().includes(newControlID) ? newControlID : Data.controllingID();
            Data.setControl(newControl);
        }
    });
};

// Returns true if the indicated ID has either guessed or passed this clue
Data.hasResolved = tabID => {
    const theID = tabID ?? Util.tabID();
    return Data.resolvedIDs().includes(theID);
};

// A list of players that either passed or guessed this clue already
Data.resolvedIDs = () => Data.prevGuessIDs().concat(Data.passedIDs());

// ################ PASSING ################

// The IDs of players who have passed already this round
Data.passedIDs = () => Object.keys(_data?.pass ?? {});

Data.pass = () => {
    if (!Data.canBuzz()) return;
    if (Data.hasResolved()) return;
    const playerCount = Data.playerIDs().length;
    const passerCount = Data.resolvedIDs().length;
    const tabID = Util.tabID();
    pushStatus(`${tabID} passed! (${passerCount + 1}/${playerCount})`);
    update(gameRef, {
        [`pass/${tabID}`]: true,
    }).then(() => {
        if (Data.allResolved()) {
            Util.wait(500).then(() => pushStatus('Everyone passed!'));
            Util.wait(1500).then(Data.endCurrentClue);
        }
    }).catch(console.error);
};

// ################ BUZZING ################

Data.canBuzz = () => _data?.buzz?.canBuzz ?? false;
Data.allResolved = () => Data.resolvedIDs().length === Data.playerIDs().length;

Data.startBuzzing = () => {
    const buzzRef = child(gameRef, 'buzz');
    runTransaction(buzzRef,
        _ => ({canBuzz: true, buzzer: null}),
        {applyLocally: false},
    )
        .then(() => pushStatus('Buzzing is allowed!'))
        .catch(console.error);
};

Data.buzzIn = () => {
    const tabID = Util.tabID();
    if (Data.prevGuessIDs().includes(tabID)) return;

    runTransaction(
        child(gameRef, 'buzz/buzzer'),
        prev => prev ? undefined : tabID,
        {applyLocally: false},
    ).then(result => {
        if (result.committed) Data.startGuess();
    }).catch(console.error);
};

// ################ Announcements ################

Data.announcement = () => _data?.announcement ?? null;

Data.announce = announcement => {
    console.debug(announcement);
    update(gameRef, {
        announcement: announcement,
    }).catch(console.error);

    Util.wait(3000).then(() => Data.announce(null));
};

// ################ CURRENT GUESS ################

Data.prevGuessIDs = () => Object.keys(_data?.guesses ?? {});

Data.currentGuess = () => {
    const guesses = _data?.guesses ?? {};
    for (const [tabID, guess] of Object.entries(guesses)) {
        if (!guess.resolved) return {tabID: tabID, ...guess};
    }
    return null;
};

Data.startGuess = tabID => {
    tabID = tabID ?? Util.tabID();

    update(gameRef, {
        'buzz/canBuzz': Data.isFinalRound() ? null : false,
        [`guesses/${tabID}`]: {time: serverTimestamp()},
        pass: null, // passes reset on each guess
    }).catch(console.error);

    if (Data.isFinalRound()) {
        pushStatus(`${tabID} is up!`);
    } else {
        pushStatus(`${tabID} is guessing...`);
        Data.announce(Data.nameFor(tabID));
    }
};

Data.revealAnswerToGuesser = () => {
    const tabID = Util.tabID();
    update(gameRef, {
        [`guesses/${tabID}/revealed/`]: true,
    }).catch(console.error);

    if (Data.isFinalRound()) {
        let response = Data.finalResponse(tabID);
        if (Util.hasValue(response)) {
            pushStatus(`${tabID} answered "${response}"`);
        } else {
            pushStatus(`${tabID} did not submit a response!`);
        }
    } else {
        pushStatus(`Answer revealed to ${tabID}!`);
    }
};

Data.timeoutGuess = () => Data.resultGuess(false, true);

/**
 * Marks the current guess as correct or incorrect, without ending the guess completely.
 * @param result Pass `true` if the guess was correct, otherwise `false`.
 * @param timeout Pass `true` if the guess was incorrect because the player ran out of time.
 */
Data.resultGuess = (result, timeout) => {
    const tabID = Util.tabID();
    const value = Data.isFinalRound() ? Data.finalWager() : Data.currentClue().value;
    const dScore = (result ? 1 : -1) * value;
    update(gameRef, {
        [`guesses/${tabID}/result/`]: result,
        [`guesses/${tabID}/timeout/`]: timeout ? true : null,
        [`scores/${tabID}/`]: increment(dScore),
    }).catch(console.error);

    if (timeout) {
        pushStatus(`Times up! ${dScore} for ${tabID}`);
    } else if (result) {
        pushStatus(`Correct! +${dScore} for ${tabID}`);
    } else {
        pushStatus(`Incorrect! ${dScore} for ${tabID}`);
    }

    Util.wait(1000).then(() => Data.resolveGuess(result));
};

/**
 * Ends the current guess and moves the game to the next state immediately.
 * @param result Pass `true` if the guess was correct, `false` otherwise.
 */
Data.resolveGuess = result => {
    update(gameRef, {
        [`guesses/${Util.tabID()}/resolved/`]: true,
    }).catch(console.error);

    if (Data.isFinalRound()) {
        const rScores = Data.sortedScores().reverse().map(s => s.tabID);
        const prevGuessIDs = Data.prevGuessIDs();
        const nextID = rScores.find(id => !prevGuessIDs.includes(id));

        if (nextID) {
            Data.startGuess(nextID);
        } else {
            Data.endCurrentClue();
        }
    } else if (result || Data.currentClue()?.isDD || Data.allResolved()) {
        const newControlID = result ? Util.tabID() : Data.controllingID();
        Data.endCurrentClue(newControlID);
    } else {
        Data.startBuzzing();
    }
};

// ################ DAILY DOUBLE ################

Data.setDDWager = wager => {
    update(gameRef, {'currentClue/value': wager})
        .then(Data.startGuess)
        .catch(console.error);
    pushStatus(`${Util.tabID()} has ${wager} at stake...`);
};

// ################ FINAL CLUE ################

Data.finalCatRevealed = () => !!_data?.final?.catRevealed;
Data.revealFinalCat = () => {
    update(gameRef, {
        'final/catRevealed/': true,
    }).catch(console.error);
    pushStatus('Players, enter your wager now');
};

Data.finalClueTime = () => _data?.final?.clueTime;
Data.revealFinalClue = () => {
    update(gameRef, {
        'final/clueTime/': serverTimestamp(),
    }).catch(console.error);
    pushStatus('Players, enter your response now');
};

Data.finalWagers = () => _data?.final?.wagers ?? {};
Data.finalWager = tabID => Data.finalWagers()[tabID ?? Util.tabID()] ?? 0;
Data.setFinalWager = wager => {
    update(gameRef, {
        [`final/wagers/${Util.tabID()}`]: wager,
    }).then(() => {
        const ids = Data.playerIDs();
        const wagers = Data.finalWagers();
        if (ids.every(tabID => Util.hasValue(wagers[tabID]))) {
            pushStatus('All wagers are in!');
        }
    }).catch(console.error);
};

Data.finalResponsesLocked = () => _data?.final?.responsesLocked;
Data.lockFinalResponses = () => {
    update(gameRef, {'final/responsesLocked': true})
        .then(() => {
            pushStatus('Responses are locked!');
            const scores = Data.sortedScores();
            const lastPlacePlayer = scores[scores.length - 1].tabID;
            Util.wait().then(() => Data.startGuess(lastPlacePlayer));
        })
        .catch(console.error);
};

Data.finalResponses = () => _data?.final?.responses ?? {};
Data.finalResponse = tabID => Data.finalResponses()[tabID ?? Util.tabID()];

Data.setFinalResponse = response => {
    update(gameRef, {
        [`final/responses/${Util.tabID()}`]: response,
    }).catch(console.error);
};

Data.gameOver = () => _data?.final?.revealed ?? false;
Data.endGame = () => {
    update(gameRef, {
        [`final/revealed/`]: true,
    }).catch(console.error);
    pushStatus(`${Data.sortedScores()[0]?.tabID} is the champion!`);
};

// ################ ADMIN ################

Data.resetClue = () => {
    if (!Data.currentClue()) return;
    update(gameRef, {
        final: null,
        guesses: null,
        pass: null,
    }).catch(console.error);
    Data.startBuzzing();
};

/**
 * Reset the current game to initial state, or unset the game to choose a different one.
 * @param shouldUnset If `true`, the current game will be unset so you can choose a new one.
 */
Data.resetGame = shouldUnset => {
    const newScores = Util.copy(_data?.scores ?? {});
    for (let tabID in newScores) newScores[tabID] = 0;
    const gameUpdate = {
        round: 'j',
        scores: newScores,
        currentClue: null,
        buzz: null,
        guesses: null,
        playedClues: null,
        final: null,
        pass: null,
    };

    const unset = typeof shouldUnset === 'boolean' && shouldUnset;
    if (unset) gameUpdate.gameFile = null;
    update(gameRef, gameUpdate).catch(console.error);
    pushStatus(`Game was ${unset ? 'un' : 're'}set`);
};

Data.unsetGame = () => Data.resetGame(true);

Data.resetEverything = () => {
    set(gameRef, null).catch(console.error);
    Util.setStoredGameFile(null).catch(console.error);
};

Data.adjustScore = (tabID, dScore) => {
    update(gameRef, {
        [`scores/${tabID}/`]: increment(dScore),
    }).catch(console.error);
    pushStatus(`${tabID}'s score was adjusted by ${dScore}`);
};

Data.removePlayer = tabID => {
    if (Data.playerIDs().length === 1) {
        Data.resetEverything();
        return;
    }

    const removedPlayerName = Data.nameFor(tabID);

    update(gameRef, {
        [`scores/${tabID}/`]: null,
        [`delay/${tabID}/`]: null,
        [`names/${tabID}/`]: null,
        [`guesses/${tabID}/`]: null,
        [`pass/${tabID}/`]: null,
    }).then(() => {
        pushStatus(`${removedPlayerName} has left the game`);
        if (Data.controllingID() === tabID) {
            Data.setControl(Util.randomElement(Data.playerIDs()));
        }
    }).catch(console.error);
};