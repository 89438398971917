import styled from 'styled-components';
import {useEffect, useState} from 'react';
import Util from './Util';

export const Popover = ({fadeMS, close, children, canClose = true}) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        Util.wait(5).then(() => setVisible(true));
        return () => setVisible(false);
    }, []);

    fadeMS = fadeMS ?? 200;

    const backClick = e => {
        e.stopPropagation();
        if (!canClose) return;
        setVisible(false);
        Util.wait(fadeMS).then(close);
    };

    const style = {
        transition: `opacity ${fadeMS}ms ease`,
        opacity: visible ? 1 : 0,
    };

    return (
        <PopoverBack style={style} onClick={backClick}>
            <PopoverContent onClick={e => e.stopPropagation()}>
                {children}
            </PopoverContent>
        </PopoverBack>
    );
};

const PopoverBack = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  //max-height: 100%;
  //width: 100%;
  z-index: 1000;
  overflow-y: scroll;
  overscroll-behavior: contain;
  //padding: max(100px, 5%) 0;

  background-color: var(--overlayColor);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);

  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  //align-content: center;
  text-align: center;
  gap: 8px;
`;

const PopoverContent = styled.div`
  min-width: 300px;
  background-color: var(--jBlue1);
  box-shadow: 0 0 16px var(--overlayColor);
  padding: 20px;
  border-radius: 8px;
  border: 1px solid var(--backgroundColor);
  z-index: 1000;
  margin: max(60px, 5%) 0;


  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-content: center;
  //height: 100%;

  flex: 0 1 auto;

  gap: 5px;
  font-size: medium;
`;

export const ButtonRow = styled.div`
  text-align: center;
  font-size: medium;
  margin: 8px 0;
`;

export const ButtonRowContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  margin-top: 5px;

  & > * {
    flex: 1 1 auto;
  }
`;